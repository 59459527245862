<template lang="pug">
	section#ler-mais(:style="{ background }")
		h3 {{ titulo }}
		ul.posts
			li.post(v-for="conteudo, index in conteudos")
				CardPost(v-bind="conteudo", :large="index <= 1")
			li.action
				router-link(:to="{ name: action.rota }").container
					.texto
						h4 {{ action.titulo }}
						p {{ action.subtitulo }}
						span {{ action.botao }}
</template>

<script>
import qs from 'qs'

import CardPost from '@/components/CardPost/CardPost'

export default {
	name: 'component-ler-mais',
	props: {
		titulo: {
			type: String,
			default: 'Fique por dentro',
		},
		action: {
			type: Object,
			default: () => Object({
				titulo: 'Imprensa',
				subtitulo: 'A GT Company é destaque em grandes publicações. Confira as últimas notícias.',
				botao: 'Clique aqui',
				rota: 'imprensa'
			})
		},
		background: {
			type: String,
			default: 'transparent'
		},
		apiRoute: {
			type: String,
			default: 'conteudo'
		},
	},
	components: {
		CardPost,
	},
	data() {
		return {
			conteudos: [],
		}
	},
	created() {
		this.loadConteudos()
	},
	watch: {
		action: {
			deep: true,
			handler: function() {
				this.loadConteudos()
			}
		}
	},
	methods: {
		loadConteudos() {
			let params = {
				limit: 2,
				categoria: this.action.rota == 'imprensa' ? 'imprensa' : '',
			}
			this.$axios
				.get(`${this.apiRoute}?${qs.stringify(params)}`)
				.then(response => this.conteudos = response.data.registros.data);
		},
	},
}
</script>

<style lang="stylus" scoped src="./LerMais.styl"></style>
