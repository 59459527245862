<template lang="pug">
    router-link(:to="route", tag="article", :class="{ roxo }").card-post
        picture.bg
            img(:src="thumb", :alt="titulo")
        header
            p.categoria {{ categoria.nome }}
        main
            header.publicacao-autor
                time {{ data_publicacao }} 
                span.autor(v-if="autor") / {{ autor }}
            section
                h1(v-html="titulo")
                p.excerpt(v-html="resumo")
            footer
        footer
            p.ler-mais Ler mais
</template>

<script>
export default {
    name: 'component-card-post',
    props: {
        autor: {
            type: String,
            default: ''
        },
        categoria: {
            type: Object,
            default: () => Object({
                nome: '',
                slug: ''
            })
        },
        thumb: {
            type: String,
            default: ''
        },
        data_publicacao: {
            type: String,
            required: true
        },
        resumo: {
            type: String,
            required: true
        },
        slug: {
            type: String,
            required: true
        },
        titulo: {
            type: String,
            required: true
        },
        roxo: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        route() {
            let name = 'post-'
            if (this.isImprensa)
                name += 'imprensa'
            else
                name += 'blog'
            return {
                name,
                params: {
                    slug: this.slug
                },
            }
        },
        isImprensa() {
            return 'imprensa' === this.categoria.slug
        },
    },
}
</script>

<style lang="stylus" scoped src="./CardPost.styl"></style>